<template>

  <v-card flat tile class="mt-5 mx-2" >
      <v-container class="grey lighten-3" fluid >

      <v-row>
        <v-col
          v-for="depto in deptos"
          :key="depto.deptos"
          cols="12"
          sm="2"
          md="2"
        >
          <v-card class="mx-auto" max-width="260">
            <v-card-actions>
                  <v-navigation-drawer permanent>
                    <v-system-bar></v-system-bar>
                    <v-list>

                      <v-list-item link>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ depto.depto }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-icon>mdi-menu-down</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list
                      nav
                      dense
                    >
                      <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(categoria, i) in categorias"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="categoria.categoria"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-navigation-drawer>

            </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-card>

</template>

<script>
export default {
  data () {
    return {
      selectedItem: 0,
      categorias: [
        { categoria: 'Bases de maquillaje' },
        { categoria: 'Correctores' },
        { categoria: 'Maquillaje en polvo' },
        { categoria: 'Paletas de rostro' },
        { categoria: 'Rubores' },
        { categoria: 'Iluminadores' },
        { categoria: 'Bronzers' },
        { categoria: 'Fijadores de maquillaje' },
        { categoria: 'Set y estuches de maquillaje' }
      ],

      deptos: [
        {
          depto: 'Cara'
        },
        {
          depto: 'Ojos'
        },
        {
          depto: 'Labios'
        },
        {
          depto: 'Accesorios'
        },
        {
          depto: 'Cuidado de piel'
        },
        {
          depto: 'Cuidado corporal y de cabello'
        }
      ]
    }
  },

  methods: {

    regresar () {
    		this.$router.push({ name: 'documento' }).catch(error => { console.log(error) })
    }
  }
}
</script>
